import React from "react";
// @ts-ignore
import {
  HashRouter as Router,
  Route,
  Switch,
  useParams,
  Redirect,
} from "react-router-dom";

import sessionRequest, { logout } from "sessionRequest";

import LoginScreen from "components/LoginScreen";
import LoadScreen from "components/LoadScreen";
import { AppContext, User } from "components/types";
import ResetPasswordScreen from "components/ResetPasswordScreen";
import MapDataPointModal from "components/map_data_points/MapDataPointModal";
import NavBar from "util_components/bootstrap/NavBar";
import Confirm from "util_components/bootstrap/Confirm";
import MapDataPointsEditor from "components/map_data_points/MapDataPointsEditor";
import MapDataPointsContextProvider from "components/map_data_points/MapDataPointsContextProvider";
import TagButtons from "components/TagButtons";
import { WithTranslation, withTranslation } from "react-i18next";
import i18n from "i18n";

type UIState = {
  user?: User;
  dataFetched: boolean;
  showLogout: boolean;
  menuOpen: boolean;
};

class MainUI extends React.Component<WithTranslation, UIState> {
  state: UIState = {
    user: undefined,
    dataFetched: false,
    showLogout: false,
    menuOpen: false,
  };

  componentDidMount() {
    this.refreshUser();
    window.addEventListener("resize", this.onResize);
    window.addEventListener("message", this.onMessage);
  }

  refreshUser() {
    sessionRequest("/rest-auth/user/").then((response) => {
      if (response.status == 401)
        this.setState({ user: undefined, dataFetched: true });
      else
        response
          .json()
          .then((user) => this.setState({ user, dataFetched: true }));
    });
  }

  logout = () => {
    sessionRequest("/rest-auth/logout/", { method: "POST" }).then(
      (response) => {
        logout();
        this.setState({ user: undefined });
      }
    );
  };

  onResize = () => {
    const el = document.getElementById("MainUI");
    // @ts-ignore
    if (el) el.style.height = window.innerHeight;
  };

  onMessage = (event: any) => {
    const language = event.data?.language;
    if (i18n.language !== language) {
      i18n.changeLanguage(language); // fallbackLng (en) will be used when language is not set in event
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("message", this.onMessage);
  }

  render() {
    const { t } = this.props;
    const { user, dataFetched, showLogout } = this.state;

    const MapDataPoint = () => (
      // @ts-ignore
      <MapDataPointModal
        note={{ id: (useParams() as any).noteId }}
        fullScreen
      />
    );

    const ResetPassword = () => {
      const params = useParams() as any;
      return <ResetPasswordScreen uid={params.uid} token={params.token} />;
    };

    const MainUI = (props: {
      selectedNoteId?: number;
      newNote?: boolean;
      osmFeatures?: number[];
      buttons?: boolean;
    }) => (
      <div
        style={{ height: window.innerHeight }}
        className="flex-column d-flex"
        id="MainUI"
      >
        {!props.buttons && (
          <NavBar
            onIconClick={this.onNavIconClick}
            icon={user ? "account_circle" : "login"}
            iconText={user ? user.username : t("Kirjaudu")}
          >
            <h4 className="m-2">{t("FVH Palautekartta")}</h4>
          </NavBar>
        )}
        <div className="flex-grow-1 flex-shrink-1 overflow-auto">
          {props.buttons ? (
            <TagButtons />
          ) : (
            <MapDataPointsContextProvider>
              <MapDataPointsEditor {...props} />
            </MapDataPointsContextProvider>
          )}
        </div>
      </div>
    );

    return dataFetched ? (
      <AppContext.Provider value={{ user }}>
        <Router>
          <Switch>
            <Route path="/login/">
              {user ? (
                <Redirect to="" />
              ) : (
                <LoginScreen onLogin={() => this.refreshUser()} />
              )}
            </Route>
            <Route path="/resetPassword/:uid/:token">
              <ResetPassword />
            </Route>
            <Route path="/note/:noteId">
              <MapDataPoint />
            </Route>
            <Route
              path="/Notes/new/:osmId(\d+)?/"
              render={(props: any) => {
                const { osmId } = props.match.params;
                return (
                  <MainUI newNote osmFeatures={osmId && [Number(osmId)]} />
                );
              }}
            />
            <Route
              path="/Notes/:noteId(\d+)?"
              render={(props: any) => (
                <MainUI
                  selectedNoteId={
                    props.match.params.noteId &&
                    Number(props.match.params.noteId)
                  }
                />
              )}
            />
            <Route path="/map/" render={(props: any) => <MainUI />} />
            <Route path="/">
              <MainUI buttons />
            </Route>
          </Switch>
        </Router>
        {showLogout && (
          <Confirm
            title={t("Log out?")}
            onClose={() => this.setState({ showLogout: false })}
            onConfirm={this.logout}
          />
        )}
      </AppContext.Provider>
    ) : (
      <LoadScreen />
    );
  }

  onNavIconClick = () => {
    if (this.state.user) this.setState({ showLogout: true });
    else window.location.hash = "#/login/";
  };
}

export default withTranslation()(MainUI);
